
import { Show, onMount, } from "solid-js";
import { DateRangeInput } from '../SmallComponents/SmallFieldDate'
import { Typography, Card, Stack, } from "@suid/material";
import { getText } from '../SmallComponents/Translate'

interface ContractPeriodProps {
    fromDate: string;
    toDate: string;
    calendarDays: number;
    showHeader?: boolean;
    setCalendarDays: (value: number) => void;
    setFromDate: (newValue: string) => void;
    setToDate: (newValue: string) => void;
}

const calculateDaysBetween = (startDate: Date, endDate: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const startDateMs = startDate.getTime();
    const endDateMs = endDate.getTime();
    const diffMs = endDateMs - startDateMs;
    const diffDays = Math.floor(diffMs / oneDay);
    return diffDays;
}

const ContractPeriod = (props: ContractPeriodProps) => {

    const handleDateChangeFrom = (value: string) => {
        props.setFromDate?.(value);
        props.setCalendarDays(calculateDaysBetween(new Date(props.fromDate), new Date(props.toDate)));
     //   setHoursAndDays();
    };
    const handleDateChangeTo = (value: string) => {
        props.setToDate?.(value);
        props.setCalendarDays(calculateDaysBetween(new Date(props.fromDate), new Date(props.toDate)));
      //  setHoursAndDays();
    };
 
    onMount(() => {
        props.setFromDate?.(props.fromDate);
        props.setToDate?.(props.toDate);
    })
    const txtContractPeriod = getText("contractcomponent", "header")
    
    return (
        <>
            {/* Contract Allocation*/}
          
                <Stack spacing={2}>
                    <Show
                        when={props?.showHeader===true}
                    >
                    <Typography variant="h6" color="text.primary">{txtContractPeriod()}</Typography>
                    </Show>
                    <DateRangeInput defaultFromValue={props.fromDate} defaultToValue={props.toDate} fromValue={props.fromDate} toValue={props.toDate} onFromChange={handleDateChangeFrom} onToChange={handleDateChangeTo} />
                </Stack>
            
        </>
    );
}
export { ContractPeriod }