import { createSignal, createEffect, onMount, Show, } from "solid-js";

import { createStore, } from "solid-js/store";
import { Typography, Card, Stack } from "@suid/material";
import { RateCard, ExpenseCard } from '../SmallComponents/SmallFieldRateCard'

import { ContractPeriod } from '../MediumComponents/MediumContractPeriod'
import { ContractAllocation } from '../SmallComponents/SmallFieldContractAllocation'
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { ContractButtonsGroup, ContractButtonLayout } from '../SmallComponents/SmallButtonGroupDayWeekMonth'
import { getText } from '../SmallComponents/Translate'
import { isExtraSmall } from "../../../utils/sizeUtil"

const formatNumberBasedOnLocale = (numberString: string): string =>{
    const number = Number(numberString.replace(/,/g, ''));
    const formatter = new Intl.NumberFormat(navigator.language);
    return formatter.format(number);
}

const calculateNumberOfHours = (numberOfCalendarDays: number, quantity: number, rateM: string, ratePr: string) => {
    //let rateM = "7"//allocationUnits()
    //let ratePr = "7"//allocationUnitsPr()
    let totalAllocationInHours = 0
    const numberOfWorkingDaysInMonth = 20
    const numberOfWorkingDaysInWeek = 5

    console.log("Calculate with rateM=" + rateM + " and quantity=" + quantity + quantity + " and ratePr=" + ratePr)


    let numberOfUnits = quantity;
    if (rateM == "Hours") {
        if (ratePr == "Day") {

            totalAllocationInHours = numberOfCalendarDays * numberOfUnits
        } else if (ratePr == "Week") {

            totalAllocationInHours = numberOfCalendarDays / numberOfWorkingDaysInWeek * numberOfUnits
        } else if (ratePr == "Month") {
            totalAllocationInHours = numberOfCalendarDays / numberOfWorkingDaysInMonth * numberOfUnits
        }

    } else if (rateM == "Days") {
        if (ratePr == "Day") {

            totalAllocationInHours = numberOfCalendarDays * numberOfUnits
        } else if (ratePr == "Week") {

            totalAllocationInHours = numberOfCalendarDays / numberOfWorkingDaysInWeek * numberOfUnits
        } else if (ratePr == "Month") {
            totalAllocationInHours = numberOfCalendarDays / numberOfWorkingDaysInMonth * numberOfUnits
        }

    }
    return totalAllocationInHours;
}


interface ContractRatesProps {
    rateModel: string;
    expenseModel: string;
    showAllocation?: boolean;
    currency: string;
    rateValue: string;
    expenseValue: string;
    ratePer: string;
    allocationUnits: string;
    allocationUnitsPr: string;
    remoteRateValue: string;
    onSiteRateValue: string;
    remoteExpenseValue: string;
    onSiteExpenseValue: string;
    totalHours: number;
    totalOnSiteHours: number;
    totalRemoteHours: number;

    calendarDays: number;

    sumRates: string;
    sumExpenses: string;
    sumTotal: string;

    numberOfHours: string;
    numberOfRemoteHours: string;
    numberOfOnSiteHours: string;
    allocation: string;

    setHours: (newValue: string) => void;
    setAllocation: (newValue: string) => void;
    setOnSiteAllocation: (newValue: string) => void;
    setRemoteAllocation: (newValue: string) => void;

    setRateModel: (newValue: string) => void;
    setExpenseModel: (newValue: string) => void;
    setCurrency: (newValue: string) => void;
    setRateValue: (newValue: string) => void;
    setRemoteRateValue: (newValue: string) => void;
    setOnSiteRateValue: (newValue: string) => void;
    setExpenseValue: (newValue: string) => void;
    setRemoteExpenseValue: (newValue: string) => void;
    setOnSiteExpenseValue: (newValue: string) => void;
    setRatePer: (newValue: string) => void;
    setAllocationUnits: (newValue: string) => void;
    setAllocationUnitsPr: (newValue: string) => void;
    setSumRates: (newValue: string) => void;
    setSumExpenses: (newValue: string) => void;
    setSumTotal: (newValue: string) => void;
}

const ContractRates = (props: ContractRatesProps) => {
    const [showAdvancedRates, setShowAdvancedRates] = createSignal(true);

    const handleInputChange = (key: string, value: string) => {
        const setValue = props[key as keyof ContractRatesProps];

        if (key === "currency") {
            props.setCurrency(value);
        } if (key === "allocationUnits") {
            props.setAllocationUnits(value);
        } if (key === "allocationUnitsPr") {
            props.setAllocationUnitsPr(value);
        } else if (typeof setValue === "function") {
            const setValue = props[key as keyof ContractRatesProps] as ((newValue: string) => void) | undefined;
            if (setValue) {
                setValue(value);

                setHoursAndDays();
            }
        } else {
            const setValue = props[key as keyof ContractRatesProps] as ((newValue: string) => void) | undefined;
            if (setValue) {
                setValue(value);
            }
            setHoursAndDays();
            //  throw new Error(`Tried to set prop "${key}", but it's not a function. Got: ${setValue}`);
        }
    };

    const setHoursAndDays = () => {
        props.setHours(
            calculateNumberOfHours(
                props.calendarDays,
                parseInt(props.allocation, 10),
                props.allocationUnits,
                props.allocationUnitsPr
            ).toString()
        );
    };

    const [rateModel, setRateModel] = createSignal("Simple");
    const handleRateModel = (newRateModel: string) => {
        setRateModel(newRateModel);
        props.setRateModel(newRateModel);
        setShowAdvancedRates(newRateModel === "Advanced" ? true : false);
        setShowExpenseValue(expenseModel() === "AllInclusive" ? false : true);
    };

    const [expenseModel, setExpenseModel] = createSignal("AllInclusive");
    const handleExpenseModelChange = (newExpenseModel: string) => {
        setExpenseModel(newExpenseModel);
        props.setExpenseModel(newExpenseModel);
        setShowExpenseValue(newExpenseModel === "AllInclusive" ? false : true);
    };

    const [showExpenseValue, setShowExpenseValue] = createSignal(false);
    createEffect(() => {
        if (expenseModel() === "AllInclusive") {
            setShowExpenseValue(false);
            props.setExpenseValue?.('0.00');
            props.setRemoteExpenseValue?.('0.00');
            props.setOnSiteExpenseValue?.('0.00');

        } else {
            // Hours
            setShowExpenseValue(true);
        }
    });

    onMount(() => {
        setShowExpenseValue(false);
        setShowAdvancedRates(false);
    })

    createEffect(() => {
        const numberOfHours = props.totalHours;
        const numberOfOnSiteHours = props.totalOnSiteHours;
        const numberOfRemoteHours = props.totalRemoteHours;

        //let numberOfCalendarDaysAllocated = parseInt(sumCalendarDays(), 10)
        //let numberOfCalendarDaysAllocated = calculateDaysBetween(new Date(state.fromDate), new Date(state.toDate))
        // let numberOfHours = calculateNumberOfHours(numberOfCalendarDaysAllocated, parseInt(state.allocation, 10), state.allocationUnits, state.allocationUnitsPr)
        //setState('totalHours', numberOfHours);
        //setState('totalManDays', numberOfHours / 8);

        // Calculate total fees for rates
        let rateValueAsNumber = parseInt(props.rateValue, 10)
        let remoteRateValueAsNumber = parseInt(props.remoteRateValue, 10)
        let onSiteRateValueAsNumber = parseInt(props.onSiteRateValue, 10)
        let sumR = rateValueAsNumber * numberOfHours + remoteRateValueAsNumber * numberOfRemoteHours + onSiteRateValueAsNumber * numberOfOnSiteHours
        props.setSumRates(formatNumberBasedOnLocale(sumR.toString()))

        // Calculate total expenses
        let expenseValueAsNumber = parseInt(props.expenseValue, 10)
        let remoteExpenseValueAsNumber = parseInt(props.remoteExpenseValue, 10)
        let onSiteExpenseValueAsNumber = parseInt(props.onSiteExpenseValue, 10)
        let sumE = expenseValueAsNumber * numberOfHours + remoteExpenseValueAsNumber * numberOfRemoteHours + onSiteExpenseValueAsNumber * numberOfOnSiteHours;
        props.setSumExpenses(formatNumberBasedOnLocale(sumE.toString()))

        // Store the totals

        props.setSumTotal(formatNumberBasedOnLocale((sumR + sumE).toString()))

    });

    const [state, setState] = createStore({
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const txtContractPeriod = getText("contractcomponent", "header")
    const txtRateModel = getText("contractcomponent", "ratemodel")
    const txtExpenseModel = getText("contractcomponent", "expensemodel")
    const txtSimpleModel = getText("contractcomponent", "simplemodel")
    const txtOnsiteModel = getText("contractcomponent", "onsitemodel")
    const txtRemoteModel = getText("contractcomponent", "remotemodel")

    return (
        <>


            <Stack margin={1} direction={isExtraSmall() ? "column" : "row"} spacing={1}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h6" color="text.primary">{txtRateModel()} </Typography>

                    <ContractButtonsGroup
                        layout={ContractButtonLayout.RateModel}
                        value={rateModel()}
                        onChange={(newRateModel) => {
                            handleRateModel(newRateModel);
                        }}
                    />

                </Stack>

            </Stack>
            <Show
                when={props?.showAllocation}
            >
                <CardWithMinimize header={"Allocation" as string} type="stack" direction="column" defaultOpen={true}>
                    <Stack margin={2} spacing={2}>
                        <ContractPeriod
                            fromDate={state.fromDate}
                            setFromDate={(newValue) => handleChange('fromDate', newValue)}
                            toDate={state.toDate}
                            setToDate={(newValue) => handleChange('toDate', newValue)}
                            showHeader={false}
                            calendarDays={state.calendarDays}
                            setCalendarDays={(newValue) => handleChange('calendarDays', newValue)}
                        />

                        <ContractAllocation
                            id={"allocationOnsite"} label={rateModel() === "Advanced" ? "Onsite Allocation" as string : "Allocation" as string}
                            showHeader={false}
                            value={props.allocation}
                            onValueChange={newValue => handleInputChange("allocation", newValue)}

                            allocationUnits={props.allocationUnits}
                            onAllocationUnitsChange={newValue => handleChange("allocationUnits", newValue)}

                            allocationUnitsPer={props.allocationUnitsPr}
                            onAllocationUnitsPerChange={newValue => handleChange("allocationUnitsPr", newValue)}
                        />

                        <Show
                            when={rateModel() === "Advanced"}
                        >
                            <ContractAllocation
                                id={"allocationRemote"} label={"Remote Allocation" as string}

                                value={props.allocation}
                                onValueChange={newValue => handleInputChange("allocationRemote", newValue)}

                                allocationUnits={props.allocationUnits}
                                onAllocationUnitsChange={newValue => handleInputChange("allocationUnits", newValue)}

                                allocationUnitsPer={props.allocationUnitsPr}
                                onAllocationUnitsPerChange={newValue => handleInputChange("allocationUnitsPr", newValue)}
                            />
                        </Show>
                    </Stack>
                </CardWithMinimize>
            </Show>

            <CardWithMinimize header={"Rate" as string} type="stack" direction="column" defaultOpen={true}>
                <Stack margin={2} spacing={2}>

                    <RateCard
                        title={rateModel() === "Advanced" ? "Onsite Rate" as string : "Rate" as string}
                        type="Onsite"
                        showHeader={false}
                        currency={props.currency}

                        currencyOnChange={value => handleInputChange("currency", value)}

                        allocationUnits={props.allocationUnits}
                        allocationUnitsOnChange={value => handleInputChange("allocationUnits", value)}
                        allocationUnitsPer={props.allocationUnitsPr}
                        allocationUnitsPerOnChange={value => handleInputChange("allocationUnitsPr", value)}

                        rateValue={props.rateValue}
                        rateOnChange={value => handleInputChange("rateValue", value)}
                    />

                    <Show
                        when={rateModel() === "Advanced"}
                    >
                        <RateCard
                            title={"Remote Rate" as string}
                            type="Remote"
                            showHeader={false}
                            currency={props.currency}

                            currencyOnChange={value => handleInputChange("currency", value)}

                            allocationUnits={props.allocationUnits}
                            allocationUnitsOnChange={value => handleInputChange("allocationUnits", value)}
                            allocationUnitsPer={props.allocationUnitsPr}
                            allocationUnitsPerOnChange={value => handleInputChange("allocationUnitsPr", value)}

                            rateValue={props.rateValue}
                            rateOnChange={value => handleInputChange("rateValue", value)}
                        />
                    </Show>
                </Stack>
            </CardWithMinimize>


            <Show
                when={rateModel() === "Advanced"}
            >
                <CardWithMinimize header={txtExpenseModel() as string} type="stack" direction="column" defaultOpen={true}>


                    <Stack margin={2} spacing={2}>
                        <ContractButtonsGroup
                            layout={ContractButtonLayout.ExpenseType}
                            value={expenseModel()}
                            onChange={(newExpenseModel) => {
                                handleExpenseModelChange(newExpenseModel);
                            }}
                        />

                        <ExpenseCard
                            title={rateModel() === "Advanced" ? "Onsite Expense" as string : "Expense" as string}
                            type="Onsite"
                            showHeader={false}
                            currency={props.currency}
                            currencyOnChange={value => handleInputChange("currency", value)}
                            expenseValue={props.expenseValue}
                            expenseOnChange={value => handleInputChange("expenseValue", value)}
                        />

                        <ExpenseCard
                            title={"Remote Expense" as string}
                            type="Remote"
                            showHeader={false}
                            currency={props.currency}
                            currencyOnChange={value => handleInputChange("currency", value)}
                            expenseValue={props.expenseValue}
                            expenseOnChange={value => handleInputChange("expenseValue", value)}
                        />

                    </Stack>
                </CardWithMinimize>
            </Show>



        </>
    );
}

export { ContractRates }