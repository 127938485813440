
import { Typography, Stack } from "@suid/material";
import { FieldExpenseList } from './SmallFieldExpenseList' 
import { ContractButtonsGroup, ContractButtonLayout } from './SmallButtonGroupDayWeekMonth'
 
import { Show, createSignal } from "solid-js";
import { FieldCurrencyValue } from "../SmallComponents/SmallFieldCurrencyValue";
import { getText } from '../SmallComponents/Translate'
import { isExtraSmall } from "../../../utils/sizeUtil"


interface RateCardProps {
    title: string;
    type: string;

    currency: string;
    showHeader?: boolean;

    rateValue: string;
    rateOnChange: (value: string) => void;

    allocationUnits: string;
    allocationUnitsOnChange: (value: string) => void;
    allocationUnitsPer: string;
    allocationUnitsPerOnChange: (value: string) => void;

    currencyOnChange: (value: string) => void;
}

const RateCard = (props: RateCardProps) => {

    const [showExpenseList, setShowExpenseList] = createSignal(false)
    const [textValue, setTextValue] = createSignal(props.rateValue);

    const rateOnChange = (newValue: string) => {
        setTextValue(newValue)
        props.rateOnChange(newValue);

    }
    const quantityId = props.type + "Quantity"
    const txtQuantity = getText("smallcomponent", "quantity")
    const quantityLabel = props.type + " " + txtQuantity()
    const txtFlatRate = getText("smallcomponent", "flatrate")
    const txtPer = getText("smallcomponent", "per")
    const txtAllocation = getText("smallcomponent", "allocation")
    const txtExpense = getText("smallcomponent", "expense")
    const txtShowExpenses = getText("smallcomponent", "showexpenses")
    return (
        <>
            {/* Advanced Rate Model */}

            <Stack direction="column" spacing={2}>

                <Show
                    when={props?.showHeader === true}
                >
                    <Typography variant="h6" color="text.primary"> {props.title}</Typography>
                </Show>
                <Stack direction={isExtraSmall() ? "column" : "row"} spacing={1} alignItems="left">

                    {/* Enter currency, rate and expense value*/}
                   
                    <FieldCurrencyValue id="flatRateId" label={props?.showHeader === false ? props.title : txtFlatRate()} />

                    <Stack direction="row" spacing={1} alignItems="center">
                    <Typography color="text.secondary">  {txtPer()} </Typography>
                   
                    <ContractButtonsGroup
                        layout={ContractButtonLayout.Currency}
                        value={props.allocationUnitsPer}
                        onChange={props.allocationUnitsPerOnChange}
                    />
                    

                  
                        <Typography variant="body2" color="text.secondary">{textValue()} {props.currency} {txtPer()}  {props.allocationUnitsPer}</Typography>
                        </Stack>
                </Stack>
               

            </Stack>


        </>
    )
}

interface ExpenseCardProps {
    title: string;
    type: string;
    showHeader?: boolean;
    currency: string;

    expenseValue: string;


    expenseOnChange: (value: string) => void;
    currencyOnChange: (value: string) => void;
}

const ExpenseCard = (props: ExpenseCardProps) =>{


    const [textValue, setTextValue] = createSignal(props.expenseValue);

    const expenseId = props.type + "Expense"
    const txtQuantity = getText("smallcomponent", "quantity")

    const txtExpense = getText("smallcomponent", "expense")
    const txtShowExpenses = getText("smallcomponent", "showexpenses")
    return (
        <>

            {/* Expense */}

            <Stack direction="column" spacing={2}>

                <Show
                    when={props.showHeader === true}
                >
                    <Typography variant="h6" color="text.primary"> {props.title}</Typography>
                </Show>
                <FieldExpenseList id={expenseId} label={props.showHeader === false ? props.title : txtExpense() as string} />

            </Stack>


        </>
    )
}

export { RateCard, ExpenseCard }


{/* Enter allocation for remote work*/ }
                    // <Typography variant="h6" color="text.primary"> {txtAllocation()}</Typography>
                    // <ContractAllocation
                    //     id={quantityId} label={quantityLabel}

                    //     value={props.numberOfHours}
                    //     onValueChange={props.hoursOnChange}

                    //     allocationUnits={props.allocationUnits}
                    //     onAllocationUnitsChange={props.allocationUnitsOnChange}

                    //     allocationUnitsPer={props.allocationUnitsPer}
                    //     onAllocationUnitsPerChange={props.allocationUnitsPerOnChange}
                    // />
