import Box from "@suid/material/Box";
import Chip from "@suid/material/Chip";
import Stack from "@suid/material/Stack";
import FormControl from "@suid/material/FormControl"; 
import InputLabel from "@suid/material/InputLabel"; 
import MenuItem from "@suid/material/MenuItem";
import OutlinedInput from "@suid/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@suid/material/Select";
import { Theme, useTheme } from "@suid/material/styles";
import { createSignal } from "solid-js"; 

import { ExpenseType, expenseList } from "../DataLists/DataExpenseList";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      "max-height": `${ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}px`,
      width: `${250}px`,
    },
  },
};

const names = [
  "(T) All Travel",
  "(T) - Train",
  "(T) - Taxi",
  "(T) - Subway/Metro/Tram",
  "(T) - Public transportation Card/Pass",
  "(T) - Rideshare",
  "(T) - Rental Car",
  "(T) - Limo",
  "(T) - Mileages (own car)",
  "(T) - Bus",
  "(T) - Ferry",
  "(T) - Bridge",
  "(T) - Airfare",
  "(T) - Parkering/Toll",
  "(A) Accommodation",
  "(A) - Hotel/Motel/Hostel",
  "(A) - Apartment",
  "(A) - Air B&B",
  "(A) - Private Accomendation",
  "(F) All Food",
  "(F) - Dinner",
  "(F) - Lunch",
  "(F) - Breakfast",
  "(O) Others",
  "(O) - Allowances",
  "(O) - Preapproved costs",
];

const getStyles = (name: string, expenseName: readonly string[], theme: Theme) => {
  return {
    "font-weight":
      expenseName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface FieldExpenseListProps {
  label: string;
  id: string;
  setLabel?: (value: string) => void;
}

const FieldExpenseList = (props: FieldExpenseListProps) => {
  const theme = useTheme();
  const [expenseName, setExpenseName] = createSignal<string[]>([]);
  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;

    setExpenseName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>

      <Stack direction="column" spacing={0.1}>
       
        <FormControl
        fullWidth 
        
        >
             
          <InputLabel id={props.id}>{props.label} </InputLabel>
          
          <Select
            labelId={props.id}
            id={props.id}
            multiple
            value={expenseName()}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem value={name} style={getStyles(name, expenseName(), theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>
        
        </FormControl>
      </Stack>
    </div>
  );
}

export { FieldExpenseList } 