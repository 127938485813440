import { Show, createSignal, Switch as SolidSwitch, Match, on } from "solid-js";
import { ToggleButton, ToggleButtonGroup, Popover, Typography } from "@suid/material";

import { HourIcon, DayIcon, WeekIcon, MonthIcon, ReceiptIcon, AllInclusiveIcon } from "../icons"

import { getText } from '../SmallComponents/Translate'
 
enum ContractButtonLayout {
    Quantity = 'Quantity',
    QuantityPer = 'QuantityPer',
    Currency = 'Currency',
    ExpenseType = 'ExpenseType',
    RateModel = 'RateModel'
}

interface ContractButtonsGroupProps {
    layout: ContractButtonLayout;
    value: string;
    onChange?: (value: string) => void;
}

const ContractButtonsGroup = ({ layout = ContractButtonLayout.Quantity, value, onChange }: ContractButtonsGroupProps) => {
    const [selectedValue, setSelectedValue] = createSignal(value);
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const [popoverText, setPopoverText] = createSignal<string>("");

    const handleChange = (newValue: string) => {
        setSelectedValue(newValue);
        onChange?.(newValue);
    };

    const handlePopoverOpen = (event: MouseEvent & { currentTarget: Element }, popoverText: string) => {
        setAnchorEl(event.currentTarget);
        setPopoverText(popoverText);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = () => Boolean(anchorEl());
    const id = () => (open() ? "simple-popover" : undefined);
 
    const renderButton = (value: string, label: string, popoverText: string, Icon: any) => (
        <ToggleButton
            value={value}
            onMouseEnter={(event: MouseEvent & { currentTarget: Element }) => handlePopoverOpen(event, popoverText)}
            onMouseLeave={() => handlePopoverClose()}
            aria-describedby={id()}
            onClick={() => handleChange(value)}
        >
            <Show
                when={Icon}
            >
                <Icon />
            </Show>
            <Show
                when={label}
            >
                {label}
            </Show>
        </ToggleButton>
    )

    const txtReimburseAll = getText("smallcomponent", "allreimbursedlabel")
    const txtNotFound = getText("default", "notfound")
    const txtQuantityHours = getText("smallcomponent", "quantityhourslabel")
    const txtQuantityDays = getText("smallcomponent", "quantitydayslabel")
    const txtAllocationDay = getText("smallcomponent", "allocationdaylabel")
    const txtAllocationWeek = getText("smallcomponent", "allocationweeklabel")
    const txtAllocationMonth = getText("smallcomponent", "allocationmonthlabel")
    const txtValueHour=getText("smallcomponent", "valuehourlabel")
    const txtValueDay = getText("smallcomponent", "valuedaylabel")
    const txtValueWeek=getText("smallcomponent", "valueweeklabel")
    const txtValueMonth =getText("smallcomponent", "valuemonthlabel")
    const txtAllInclusive = getText("smallcomponent", "allinclusivelabel")
    const txtAll = getText("smallcomponent", "all")
    const txtMax = getText("smallcomponent", "max")
    const txtMaxLabel = getText("smallcomponent", "maxreimbursedlabel")
    const txtFixed = getText("smallcomponent", "fixed")
    const txtFixedLabel = getText("smallcomponent", "fixedreimbursedlabel")
    const txtSimple =getText("smallcomponent", "simple")
    const txtSimpleLabel = getText("smallcomponent", "simpleexpenselabel")
    const txtAdvanced = getText("smallcomponent", "advanced")
    const txtAdvancedLabel = getText("smallcomponent", "advancedexpenselabel")

    return (
        <>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none" }}
                open={open()}
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ px: 2, py: 2 }} variant="body2" align="center" gutterBottom>
                    {popoverText()}
                </Typography>
            </Popover>

            <ToggleButtonGroup
                color="primary"
                value={selectedValue()}
                size="small"
                exclusive
                aria-describedby={id()}
            >
                <SolidSwitch fallback={<div>{txtNotFound()}</div>}>
                    <Match when={layout === ContractButtonLayout.Quantity}>
                        {renderButton("Hours", "", txtQuantityHours() as string , HourIcon)}
                        {renderButton("Days", "", txtQuantityDays() as string, DayIcon)}
                    </Match>
                    <Match when={layout === ContractButtonLayout.QuantityPer}>
                        {renderButton("Day", "", txtAllocationDay() as string, DayIcon)}
                        {renderButton("Week", "",txtAllocationWeek() as string, WeekIcon)}
                        {renderButton("Month", "",txtAllocationMonth() as string, MonthIcon)}
                    </Match>
                    <Match when={layout === ContractButtonLayout.Currency}>
                        {renderButton("Hour", "", txtValueHour() as string, HourIcon)}
                        {renderButton("Day", "", txtValueDay() as string, DayIcon)}
                        {renderButton("Week", "",txtValueWeek() as string, WeekIcon)}
                        {renderButton("Month", "",txtValueMonth() as string, MonthIcon)}
                    </Match>
                    <Match when={layout === ContractButtonLayout.ExpenseType}>
                        {renderButton("AllInclusive", "",txtAllInclusive() as string, AllInclusiveIcon)}
                        {renderButton("OnTopByReceipt", txtAll() as string,txtReimburseAll() as string, ReceiptIcon)}
                        {renderButton("OnTopMaxValue", txtMax() as string,txtMaxLabel() as string, ReceiptIcon)}
                        {renderButton("OnTopFixedValue", txtFixed() as string,txtFixedLabel() as string, ReceiptIcon)}
                    </Match>
                    <Match when={layout === ContractButtonLayout.RateModel}>
                        {renderButton("Simple", txtSimple() as string, txtSimpleLabel() as string, null)}
                        {renderButton("Advanced", txtAdvanced() as string, txtAdvancedLabel() as string, null)}

                    </Match>

                </SolidSwitch>
            </ToggleButtonGroup>
        </>
    )
}
export { ContractButtonLayout, ContractButtonsGroup }