
import { Show, createEffect, createSignal } from "solid-js";
import { Typography, Stack, TextField, Box } from "@suid/material";
import { ContractButtonsGroup, ContractButtonLayout } from './SmallButtonGroupDayWeekMonth'
import { getText } from '../SmallComponents/Translate'
import { isExtraSmall } from "../../../utils/sizeUtil"

interface ContractAllocationProps {
    id: string;
    label: string;
    value: string;
    allocationUnits: string;
    allocationUnitsPer: string;
    showHeader?: boolean;
    onValueChange: (value: string) => void;
    onAllocationUnitsChange: (value: string) => void;
    onAllocationUnitsPerChange: (value: string) => void;
}

const ContractAllocation = (props: ContractAllocationProps) =>{
    const [showButtons, setShowButtons] = createSignal(true);
    const [textValue, setTextValue] = createSignal(props.value);

    const handleChange = (event: Event) => {
        const newValue = (event.target as HTMLInputElement).value;
        setTextValue(newValue);
        props.onValueChange?.(newValue);
    };


    const txtPer = getText("smallcomponent", "per")

    const [textString, setTextString] = createSignal("")
    createEffect(() => {
        setTextString(props.value + " " + props.allocationUnits + txtPer() + props.allocationUnitsPer)
    })

    return (
        <>
            <Stack direction="column" spacing={1} alignItems="left">

                <Show
                    when={!isExtraSmall()}
                    fallback={
                        <>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <TextField sx={{ width: 115 }}
                                    required id={props.id} label={props.label} type="number" variant="outlined"
                                    size="small"
                                    defaultValue={props.value}
                                    value={props.value}

                                    onChange={handleChange}

                                />

                                {/* Quantity has to be hours or days */}
                                <ContractButtonsGroup
                                    layout={ContractButtonLayout.Quantity}
                                    value={props.allocationUnits}
                                    onChange={props.onAllocationUnitsChange}
                                />
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                < Typography color="text.secondary">{txtPer()}</Typography>
                                <ContractButtonsGroup
                                    layout={ContractButtonLayout.QuantityPer}
                                    value={props.allocationUnitsPer}
                                    onChange={props.onAllocationUnitsPerChange}
                                />


                                <Typography variant="body2" color="text.secondary">{textString()}</Typography>
                            </Stack>
                        </>
                    }
                >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <TextField sx={{ width: 115 }}
                            required id={props.id} label={props.label} type="number" variant="outlined"
                            size="small"
                            defaultValue={props.value}
                            value={props.value}

                            onChange={handleChange}

                        />

                        {/* Quantity has to be hours or days */}
                        <ContractButtonsGroup
                            layout={ContractButtonLayout.Quantity}
                            value={props.allocationUnits}
                            onChange={props.onAllocationUnitsChange}
                        />
                        {/* Days can be pr week or month and hours pr day, week or month */}
                        <Typography color="text.secondary">{txtPer()}</Typography>
                        <ContractButtonsGroup
                            layout={ContractButtonLayout.QuantityPer}
                            value={props.allocationUnitsPer}
                            onChange={props.onAllocationUnitsPerChange}
                        />


                        <Typography variant="body2" color="text.secondary">{textString()}</Typography>

                    </Stack>
                </Show>

            </Stack >
        </>
    )
}

export { ContractAllocation }